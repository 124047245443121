const phoneReg = /^1\d{10}$/;

export default {
    data() {
        return {
            getValidateCode: false,
            counter: 59,
            codeKey: '',
        }
    },

    beforeMount() {
        const counter = localStorage.getItem('loginCounter');
        const phone = localStorage.getItem('phone');
        if (counter && +counter > 0) {
            this.getValidateCode = true;
            this.counter = +counter;
            this.phone = phone;
            this.initInterval();
        }
    },

    methods: {
        getCode() {
            if (this.getValidateCode || !this.phone) {
                return;
            }
            const phone = this.phone.replace(/\s/g, '')
            if (!phoneReg.test(phone)) {
                this.$toast.error('请输入正确的手机号码');
                return;
            }
            this.$loading.show();
            this.getCodeRequest(phone).then(res => {
                this.$loading.hide();
                this.$toast.success('验证码发送成功');
                this.codeKey = res.data;
                this.getValidateCode = true;
                this.counter = 59;
                this.initInterval();
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败');
            });
            
        },

        initInterval(){
            const interval = setInterval(() => {
                if (this.counter === 0) {
                    localStorage.removeItem('loginCounter');
                    localStorage.removeItem('phone');
                    clearInterval(interval);
                    this.getValidateCode = false;
                    return;
                }
                this.counter--;
                localStorage.setItem('loginCounter', '' + this.counter);
                localStorage.setItem('phone', this.phone);
            }, 1000);
        },
    },
}