<template>
<div class="user-info">
    <v-container pa-6>
        <div v-if="!editFlag">
            <div class="info-item">
                <span>姓名</span>
                <span>{{hasCompletedInfo ? userInfo.realName : userInfo.userNameHide}}</span>
            </div>
            <div class="info-item mb">
                <span>手机号</span>
                <span>{{hasCompletedInfo ? getMobileHide(userInfo.phone) : ''}}</span>
            </div>
            <v-btn
                class="button-submit"
                rounded
                depressed
                color="primary"
                v-if="!hasCompletedInfo"
                @click="edit"
            >
                编辑
            </v-btn>
        </div>
        <div v-if="editFlag" class="user-form">
            <password-rule />
            <div class="input-item">
                <span>姓名</span>
                <input 
                    v-model="realName"
                    maxlength="11"
                />
            </div>
            <div class="input-item">
                <span>手机号</span>
                <input 
                    type="tel"
                    v-model="phone"
                    maxlength="11"
                />
            </div>
            <div class="input-item validate-code">
                <span>验证码</span>
                <input 
                    type="tel"
                    v-model="code"
                    maxlength="6"
                />
                <v-btn
                    class="button-send"
                    rounded
                    depressed
                    color="primary"
                    :disabled="getValidateCode || !phone"
                    @click="getCode"
                >
                    <span v-if="!getValidateCode">获取验证码</span>
                    <span v-if="getValidateCode">{{counter}}s 后重发</span>
                </v-btn>
            </div>
            <div class="input-item">
                <span>密码</span>
                <input 
                    type="password"
                    v-model="password"
                    maxlength="20"
                />
            </div>
            <div class="input-item">
                <span>确认密码</span>
                <input 
                    type="password"
                    v-model="passwordConfirm"
                    maxlength="20"
                />
            </div>
            <v-btn
                class="button-submit"
                rounded
                depressed
                color="primary"
                :disabled="!canSubmit"
                @click="submit"
            >
                提交
            </v-btn>
            <v-btn
                class="button-submit"
                rounded
                depressed
                @click="editFlag = false"
            >
                取消
            </v-btn>
        </div>
    </v-container>
</div>
</template>

<script>
import PasswordRule from '@/components/password-rule';
import validateCodeMixins from '@/mixins/validateCode.mixins';
import {setUserInfo, getInfoMessage, getUserInfo} from '@/service/service';
import {getMobileHide} from '@/util/util';
import md5 from 'js-md5';

const phoneReg = /^1\d{10}$/;
const passReg = /^[A-Za-z]{1}(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,19}$/;
const codeReg = /^[0-9]{4,6}$/;
export default {
    name: 'UserInfo',

    components: {
        PasswordRule
    },

    mixins: [validateCodeMixins],

    data() {
        return {
            userInfo: {},
            realName: '',
            phone: '',
            code: '',
            password: '',
            passwordConfirm: '',
            editFlag: false,
            hasToken: true,
            hasCompletedInfo: false, // 是否完善信息
        }
    },

    computed: {
        canSubmit() {
            return this.realName && this.phone && this.code && this.password;
        },
    },

    beforeMount() {
        let userInfo = localStorage.getItem('userInfo')
        if (userInfo) {
            userInfo = JSON.parse(userInfo)
            this.userInfo = {
                realName: userInfo.realName,
                phone: userInfo.mobile,
                userNameHide: userInfo.userNameHide
            }
            this.hasCompletedInfo = userInfo.tmpFlag === '1';
        } else {
            this.getUserInfo()
        }
    },

    methods: {
        getMobileHide,
        edit() {
            this.editFlag = true;
        },
        getCodeRequest(phone) {
            return getInfoMessage({
                phone: phone,
            })
        },
        submit() {
            const phone = this.phone.replace(/\s/g, '')
            if (!this.realName) {
                this.$toast.error('请输入姓名');
                return
            }
            if (!phoneReg.test(phone)) {
                this.$toast.error('请输入正确的手机号码');
                return;
            }
            if (!codeReg.test(this.code)) {
                this.$toast.error('验证码错误');
                return;
            }
            if (!passReg.test(this.password)) {
                this.$toast.error('密码格式错误');
                return;
            }
            if (!this.passwordConfirm) {
                this.$toast.error('请再次输入密码');
                return;
            }
            if (this.password !== this.passwordConfirm) {
                this.$toast.error('两次密码不一致');
                return;
            }
            this.$loading.show()
            setUserInfo({
                realName: this.realName,
                mobile: phone,
                password: md5(this.password).toLocaleUpperCase(),
                code: this.code,
                key: this.codeKey,
            }).then(() => {
                this.$loading.hide()
                this.$toast.success('设置成功');
                setTimeout(() => {
                    this.editFlag = false;
                    this.hasCompletedInfo = true;
                    this.getUserInfo();
                });
            }).catch(err => {
                this.$loading.hide()
                this.$toast.error(err.msg || '请求失败')
            })
        },
        getUserInfo() {
            this.$loading.show()
            getUserInfo().then(res => {
                this.$loading.hide();
                res.data.userNameHide = res.data.realName.substring(0, 8) + '...';
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                this.hasCompletedInfo = res.data.tmpFlag === '1';
                this.userInfo = {
                    realName: res.data.realName,
                    phone: res.data.mobile
                }
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败');
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.info-item {
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #dedede;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.mb {
        margin-bottom: 24px;
    }
    span {
        display: block;
    }
    span:first-child {
        width: 30%;
        color: #333333;
    }
    span:last-child {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #777777;
    }
}
.validate-code {
    padding-right: 0;
    .button-send {
        height: calc(100% + 2px) !important;
        margin-right: -1px;
        margin-top: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
.button-submit {
    margin-top: 12px;
}
</style>